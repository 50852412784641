import React, { useEffect, useState } from "react";
import style from "./CustomPeriod.module.css";
import SectionTitle from "@Components/ui/atoms/SectionTitle/SectionTitle";
import CardTemplate from "@Components/ui/atoms/CardTemplate/CardTemplate";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import CardTotalSales from "@Components/ui/atoms/CardTotalSales/CardTotalSales";
import CardHorBarGraph from "@Components/ui/atoms/CardHorBarGraph/CardHorBarGraph";
// import CardGiftcards from "@Components/ui/atoms/CardGiftcards/CardGiftcards";
import ServicesModal from "@Components/ui/molecules/ServicesModal/ServicesModal";
import MonthSalesCalendar from "@Components/ui/atoms/MonthSalesCalendar/MonthSalesCalendar";
import { MAX_WIDTH_MOBILE } from "@Models/const/responsive";
// @ts-ignore
import { DateInput, ButtonWithoutIcon } from "@viuti/recursos";
import { getCustomPeriodSales } from "@Adapters/getCustomPeriod.adapter";
import CardsWithIcon from "@Components/ui/atoms/CardsWithIcon/CardsWithIcon";
import { CalendarIcon, CashIcon, ThreePeopleIcon } from "@Models/icons";
import DynamicGraph from "@Components/ui/atoms/DynamicGraph/DynamicGraph";
import { mockMonthlySalesChart } from "@Components/ui/atoms/DynamicGraph/mock_data/mock_data";

const CustomPeriod = () => {
  const salesState = useAppSelector((state) => state.sales);
  const [screenSize, setScreenSize] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("Servicios");
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };
  const [initialForm, setInitialForm] = useState({
    startDate: formatDate(startOfMonth),
    endDate: formatDate(today),
  });
  const [form, setForm] = useState({
    startDate: formatDate(startOfMonth),
    endDate: formatDate(today),
  });
  const [error, setError] = useState({
    startDate: "",
    endDate: "",
  });
  const [buttonFilterIsLoading, setButtonFilterIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const setModalContent = (data: [], title: string) => {
    setModalData(data);
    setModalTitle(title);
    setModalVisible(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Función para validar las fechas
  const validateDates = (endDate: string) => {
    if (form.startDate && endDate) {
      if (new Date(form.startDate) > new Date(endDate)) {
        setError({ ...error, endDate: "Debe ser posterior a fecha inicio." });
        return false;
      }
    }
    setError({ ...error, endDate: "" });
    return true;
  };

  /**
   * Maneja la carga de datos del período personalizado
   * Valida las fechas y ejecuta el adaptador
   */
  const handleLoad = async () => {
    // Validamos que las fechas sean correctas
    if (!validateDates(form.endDate)) {
      return;
    }

    try {
      setButtonFilterIsLoading(true);
      const response = await getCustomPeriodSales(
        dispatch,
        form.startDate,
        form.endDate
      );
      setInitialForm(form);
      setButtonFilterIsLoading(false);

      if (response.status !== 200) {
        // Aquí puedes agregar un toast o notificación de error si lo deseas
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    } finally {
      setButtonFilterIsLoading(false);
    }
  };
  return (
    <>
      <SectionTitle idPeriod={null} />
      <section className={style.sectionCardsContainer}>
        <div className={style.dateContainer}>
          <div className={style.inputContainer}>
            <DateInput
              label="Fecha de inicio"
              inputProps={{
                placeholder: "Seleccionar fecha",
                value: form.startDate,
                onChange: (e) => {
                  setForm({ ...form, startDate: e.target.value });
                  // Validamos nuevamente cuando cambia la fecha inicial
                  if (form.endDate) {
                    validateDates(form.endDate);
                  }
                },
              }}
            />
          </div>
          <div className={style.inputContainer}>
            <DateInput
              label="Fecha de fin"
              inputProps={{
                placeholder: "Seleccionar fecha",
                value: form.endDate,
                onChange: (e) => {
                  const newEndDate = e.target.value;
                  setForm({ ...form, endDate: newEndDate });
                  validateDates(newEndDate);
                },
              }}
              error={error.endDate}
              touched={true}
            />
          </div>
          <ButtonWithoutIcon
            textBttn={"Cargar"}
            handleClick={handleLoad}
            isLoading={buttonFilterIsLoading}
            isPrimary={false}
          />
        </div>
        <div className={style.firstContainer}>
          <strong className={style.cardTitle}>Ingresos</strong>
          <div className={style.salesCardsColumnContainer}>
            <CardTemplate title="" flex={1}>
              <CardTotalSales
                {...salesState.customPeriodData.sales}
                isPeriod={true}
              />
            </CardTemplate>
            <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
              <CardsWithIcon
                label={"Ticket promedio"}
                data={salesState.customPeriodData.sales.sales.averageTickets}
                isMoneyFormat
                icon={CashIcon}
                iconColor={"#45348E"}
                sizeIcon={30}
              />
            </CardTemplate>
            <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
              <CardsWithIcon
                label={"Total de reservas"}
                data={salesState.customPeriodData.sales.sales.appointments}
                icon={CalendarIcon}
                iconColor={"#B554CC"}
              />
            </CardTemplate>
            <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
              <CardsWithIcon
                label={"Clientes atendidos"}
                data={salesState.customPeriodData.sales.sales.clientsAttended}
                icon={ThreePeopleIcon}
                iconColor={"#00B71D"}
                sizeIcon={28}
              />
            </CardTemplate>
          </div>
        </div>
        {screenSize?.width > MAX_WIDTH_MOBILE ? (
          <div className={style.monthSalesGraphContainer}>
            <strong className={style.cardTemplateLabel}>Ventas</strong>
            <div className={style.firstRowContainer}>
              <CardTemplate flex={3}>
                {screenSize?.width > MAX_WIDTH_MOBILE ? (
                  <DynamicGraph
                    arrayData={
                      salesState.customPeriodData.sales.monthlySalesChart
                      // mockMonthlySalesChart
                    }
                    initialStartDate={initialForm.startDate}
                    initialEndDate={initialForm.endDate}
                  />
                ) : null}
              </CardTemplate>
            </div>
          </div>
        ) : null}
        <div className={style.purchasesSalesCardsContainer}>
          <CardHorBarGraph
            title="Paquetes vendidos"
            data={salesState.customPeriodData.sales.purchases.packages}
            setModalData={setModalContent}
          />
          <CardHorBarGraph
            title="Servicios más vendidos"
            data={salesState.customPeriodData.sales.purchases.services}
            setModalData={setModalContent}
          />
          <CardHorBarGraph
            title="Productos más vendidos"
            data={salesState.customPeriodData.sales.purchases.products}
            setModalData={setModalContent}
          />
          {/* <CardTemplate title="Giftcards vendidas" flex={1}>
            <CardGiftcards
              data={salesState.monthlyData.sales.purchases.giftcards}
            />
          </CardTemplate> */}
        </div>
      </section>
      <ServicesModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        data={modalData}
        title={modalTitle}
      />
    </>
  );
};

export default CustomPeriod;
