import React, { useEffect, useRef, useState } from "react";

import { HeaderNavigation, TabNavigation } from "@Models/resources-components";
import style from "./index.module.css";
import TodayModule from "./modules/TodayModule/TodayModule";
import WeekModule from "./modules/WeekModule/WeekModule";
import MonthModule from "./modules/MonthModule/MonthModule";
import { MAX_WIDTH_MOBILE } from "@Models/const/responsive";
import CustomPeriod from "./modules/CustomPeriod/CustomPeriod";

const RenderMobile = (NAVIGATION_VIEWS) => {
  const mobileViews = [
    ...NAVIGATION_VIEWS,
    {
      name: "Periodo personalizado",
      component: (
        <section className={style.module__container}>
          <CustomPeriod />
        </section>
      ),
    },
  ];

  return <TabNavigation views={mobileViews} />;
};

const RenderLaptop = (NAVIGATION_VIEWS) => {
  const headerProps = {
    title: "Panel de estadísticas",
    previousAction: null,
    previousActionMovement: "back",
    buttonProps: {
      textBttn: "Reportes",
      handleClick: () => {},
      isDisabled: false,
      isPrimary: true,
      isHidden: true,
    },
  };

  // Creamos las vistas para el TabNavigation de escritorio
  const desktopViews = [
    {
      name: "General",
      component: (
        <div className={style.containerPeriod}>
          {NAVIGATION_VIEWS.map((item, index) => {
            return RenderComponentWithKey(item.component, index);
          })}
        </div>
      ),
    },
    {
      name: "Periodo personalizado",
      component: (
        <section className={style.module__container}>
          {/* Renderizamos el componente MonthModule en la vista de periodo personalizado */}
          <CustomPeriod />
        </section>
      ),
    },
  ];

  return (
    <>
      <HeaderNavigation {...headerProps} />
      <TabNavigation views={desktopViews} />
    </>
  );
};

const RenderComponentWithKey = (component, key) => {
  return (
    /* @ts-ignore */
    <React.Fragment key={key}>{component}</React.Fragment>
  );
};

const Index = () => {
  const mainContentRef = useRef(null);

  const NAVIGATION_VIEWS = [
    {
      name: "Hoy",
      component: (
        <section className={style.module__container}>
          <TodayModule />
        </section>
      ),
    },
    {
      name: "Semana",
      component: (
        <section className={style.module__container}>
          <WeekModule />
        </section>
      ),
    },
    {
      name: "Mes",
      component: (
        <section className={style.module__container}>
          <MonthModule />
        </section>
      ),
    },
  ];

  // Get the screen and update
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={style.viewContainer} ref={mainContentRef}>
      {screenWidth < MAX_WIDTH_MOBILE
        ? RenderMobile(NAVIGATION_VIEWS)
        : RenderLaptop(NAVIGATION_VIEWS)}
    </div>
  );
};

export default Index;
