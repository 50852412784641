interface IServiceData {
  date: string; // Fecha en formato string
  value: number; // Valor numérico asociado a la fecha
}

/**
 * Función principal que formatea los datos para ser utilizados en un gráfico
 * @param serviceData Array de datos con fechas y valores
 * @param step Valor para el escalado del gráfico
 * @param label Etiqueta para mostrar en el dataset
 */
export const formattedData = (
  serviceData: IServiceData[],
  step: number,
  initialStartDate: string,
  initialEndDate: string,
  label: string = "Ventas"
) => {
  // Parse initial and end dates
  const startDate = parseDate(initialStartDate);
  const endDate = parseDate(initialEndDate);

  // Ordenamos los datos por fecha
  const sortedData = [...serviceData].sort(
    (a, b) => parseDate(a?.date).getTime() - parseDate(b?.date).getTime()
  );

  // Si no hay datos o los datos están fuera del rango, agregamos objetos con valor 0
  const paddedData = [...sortedData];

  // Verificar si necesitamos agregar un objeto al principio
  const firstDataDate =
    sortedData.length > 0 ? parseDate(sortedData[0].date) : null;
  if (!firstDataDate || firstDataDate > startDate) {
    paddedData.unshift({
      date: formatDateKey(startDate),
      value: 0,
    });
  }

  // Verificar si necesitamos agregar un objeto al final
  const lastDataDate =
    sortedData.length > 0
      ? parseDate(sortedData[sortedData.length - 1].date)
      : null;
  if (!lastDataDate || lastDataDate < endDate) {
    paddedData.push({
      date: formatDateKey(endDate),
      value: 0,
    });
  }

  // Resto del código original
  const sortedPaddedData = paddedData.sort(
    (a, b) => parseDate(a?.date).getTime() - parseDate(b?.date).getTime()
  );

  // Definir diffDays antes de usarlo
  const diffDays =
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24); // Calcula la diferencia en días

  const { labels, data } =
    diffDays <= 62 // Si el rango es de 2 meses o menos
      ? formatDailyData(sortedPaddedData, startDate, endDate)
      : formatMonthlyData(sortedPaddedData, startDate, endDate);

  const maxData = Math.max(...data);

  return {
    labels,
    datasets: [
      {
        label,
        data: [...data, maxData + step],
        pointRadius: Array(labels.length).fill(4),
        tension: 0.3,
        fill: true,
        backgroundColor: "rgba(181, 84, 204, 0.15)",
        pointBorderColor: "rgba(57, 43, 117, 1)",
        pointBackgroundColor: "rgba(255, 255, 255, 1)",
      },
    ],
    fill: {
      propagate: true,
    },
  };
};

// Recuerda que formatDateKey es tu función existente para formatear fechas como 'YYYY-MM-DD'

/**
 * Parsea una fecha asegurando que se mantenga en la zona horaria local
 * @param dateStr String de fecha en formato YYYY-MM-DD
 * @returns Date objeto fecha, o fecha actual si el input es inválido
 */
const parseDate = (dateStr: string): Date => {
  try {
    // Verificamos si dateStr es undefined o null
    if (!dateStr) {
      return new Date();
    }

    const parts = dateStr.split("-").map(Number);

    // Verificamos si tenemos las 3 partes necesarias y son números válidos
    if (parts.length !== 3 || parts.some(isNaN)) {
      console.warn("Formato de fecha inválido:", dateStr);
      return new Date();
    }

    const [year, month, day] = parts;

    // Verificamos que los valores estén en rangos razonables
    if (year < 1900 || month < 1 || month > 12 || day < 1 || day > 31) {
      console.warn("Valores de fecha fuera de rango:", dateStr);
      return new Date();
    }

    return new Date(year, month - 1, day, 12, 0, 0);
  } catch (error) {
    return new Date();
  }
};

/**
 * Formatea los datos para visualización diaria
 * @param data Datos ordenados
 * @param startDate Fecha inicial
 * @param endDate Fecha final
 */
const formatDailyData = (
  data: IServiceData[],
  startDate: Date,
  endDate: Date
) => {
  const labels: string[] = [];
  const chartData: number[] = [];
  const currentDate = new Date(startDate);

  // Crear mapa de datos para acceso rápido
  const dataMap = new Map(
    data.map((item) => {
      const date = parseDate(item?.date);
      return [formatDateKey(date), item.value];
    })
  );

  // Verificamos si hay datos para la fecha de inicio
  const startDateKey = formatDateKey(startDate);
  if (!dataMap.has(startDateKey)) {
    labels.push(formatDayLabel(startDate));
    chartData.push(0); // Agregamos 0 para la fecha de inicio
  }

  while (currentDate <= endDate) {
    const dateKey = formatDateKey(currentDate);
    const dayLabel = formatDayLabel(currentDate);

    labels.push(dayLabel);
    chartData.push(dataMap.get(dateKey) || 0);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Verificamos si hay datos para la fecha de fin
  const endDateKey = formatDateKey(endDate);
  if (!dataMap.has(endDateKey)) {
    labels.push(formatDayLabel(endDate));
    chartData.push(0); // Agregamos 0 para la fecha de fin
  }

  return { labels, data: chartData };
};

/**
 * Formatea los datos para visualización mensual
 * @param data Datos ordenados
 * @param startDate Fecha inicial
 * @param endDate Fecha final
 */
const formatMonthlyData = (
  data: IServiceData[],
  startDate: Date,
  endDate: Date
) => {
  const labels: string[] = [];
  const monthlyData: number[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const monthLabel = formatMonthLabel(currentDate);
    labels.push(monthLabel);

    // Sumar todos los valores para este mes
    const monthTotal = data.reduce((sum, item) => {
      const itemDate = parseDate(item?.date);
      if (
        itemDate.getMonth() === currentDate.getMonth() &&
        itemDate.getFullYear() === currentDate.getFullYear()
      ) {
        return sum + item.value;
      }
      return sum;
    }, 0);

    monthlyData.push(monthTotal);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return { labels, data: monthlyData };
};

/**
 * Formatea una fecha como clave para el mapa de datos
 * @param date Fecha a formatear
 */
const formatDateKey = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * Formatea la etiqueta para visualización diaria
 * @param date Fecha a formatear
 */
const formatDayLabel = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = getMonthShortName(date.getMonth());
  return `${day} ${month}`;
};

/**
 * Formatea la etiqueta para visualización mensual
 * @param date Fecha a formatear
 */
const formatMonthLabel = (date: Date): string => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 * Obtiene el nombre corto del mes
 * @param monthIndex Índice del mes (0-11)
 */
const getMonthShortName = (monthIndex: number): string => {
  const monthsShort = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  return monthsShort[monthIndex];
};
